import React from 'react';
import { Link } from 'react-router-dom';
import isUser from '../utils/is-user';

const Title = () => (
	<h1 className="text-xl font-bold text-center text-gray-800">
		<span className="text-gray-900">
			w<span className="hidden sm:inline-block">izard</span>
			<span className="text-purple-600">xy</span>
		</span>
	</h1>
);

const NavLink = ({ children, ...rest }) => (
	<Link
		{...rest}
		className="inline-flex items-center text-sm font-medium text-gray-900 focus:outline-none focus:border-purple-700 transition duration-150 ease-in-out"
	>
		{children}
	</Link>
);

const Header = ({ viewer, loading }) => {
	return (
		<header className="bg-white">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="flex justify-between h-14">
					<div className="flex-shrink-0 flex items-center">
						<Link to="/">
							<Title />
						</Link>
					</div>
					{!loading && (
						<nav className="flex ml-2 space-x-4">
							{isUser(viewer) ? (
								<>
									<NavLink to="/profile">
										<span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
											<svg
												className="h-full w-full text-gray-300"
												fill="currentColor"
												viewBox="0 0 24 24"
											>
												<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
											</svg>
										</span>
									</NavLink>
								</>
							) : (
								<>
									<NavLink to="/register">Registrieren</NavLink>
									<NavLink to="/login">Login</NavLink>
								</>
							)}
						</nav>
					)}
				</div>
			</div>
		</header>
	);
};

export default Header;
