import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from './components/header';
import debug from './utils/debug';
import { useOrCreateViewer } from './utils/use-viewer';

const Login = lazy(() => import('./components/login'));
const Logout = lazy(() => import('./components/logout'));
const Profile = lazy(() => import('./components/profile'));
const Registration = lazy(() => import('./components/registration'));
const Start = lazy(() => import('./components/start'));
const Game = lazy(() => import('./components/game'));

const Error = () => (
	<div>Oops, an error occured.. sorry! You may try to reload your browser window.</div>
);

function App() {
	const { loading, error, viewer } = useOrCreateViewer();

	if (error) {
		debug.error({ error });
		return <Error />;
	}

	return (
		<div className="grid grid-rows-layout h-screen">
			<Header viewer={viewer} loading={loading} />
			<div className="max-w-7xl mx-auto w-full">
				<Suspense fallback={<div />}>
					<Switch>
						<Route exact path="/" component={Start} />
						<Route path="/register/:inviteCode?" component={Registration} />
						<Route path="/login" component={Login} />
						<Route path="/logout" component={Logout} />
						<Route path="/profile" component={Profile} />
						<Route exact path="/:gameId" component={Game} />
					</Switch>
				</Suspense>
			</div>
		</div>
	);
}

export default App;
