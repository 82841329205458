import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import store from './localStorage';

export const viewerQuery = gql`
	query Viewer {
		viewer {
			id
			alias
			role
			email
			inviteCode
		}
	}
`;

export const createViewerMutation = gql`
	mutation createPlayer {
		createPlayer {
			token
			player {
				id
				alias
				role
				email
				inviteCode
			}
		}
	}
`;

export const useViewer = ({ onLoaded } = {}) => {
	const { loading, error, data } = useQuery(viewerQuery, {
		onCompleted: data => {
			if (typeof onLoaded === 'function') {
				onLoaded(data?.viewer);
			}
		},
	});

	return { loading, error, viewer: data?.viewer };
};

export const useOrCreateViewer = () => {
	const { loading: loadingViewer, error: viewerError, viewer } = useViewer();
	const [
		sendCreateViewer,
		{ loading: loadingCreateViewer, error: createPlayerError },
	] = useMutation(createViewerMutation, {
		errorPolicy: 'all',
		update(cache, { data: { createPlayer } }) {
			const player = createPlayer?.player;
			cache.writeQuery({ query: viewerQuery, data: { viewer: player } });
		},
		onCompleted: async data => {
			const token = data?.createPlayer.token;
			await store.set('token', token);
		},
	});

	useEffect(() => {
		if (!loadingViewer && !viewerError && !viewer) {
			sendCreateViewer();
			return;
		}
	}, [loadingViewer, viewerError, viewer, sendCreateViewer]);

	return {
		loading: loadingViewer || loadingCreateViewer,
		error: viewerError || createPlayerError,
		viewer,
	};
};
