import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import App from './App';
// import * as serviceWorker from "./serviceWorker";
import apolloClient from './apollo/client';

//@TODO react strict mode is causing trouble with

ReactDOM.render(
	// <React.StrictMode>
	<ApolloProvider client={apolloClient}>
		<Router>
			<App />
		</Router>
	</ApolloProvider>,
	// </React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
