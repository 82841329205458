const debug = require('./debug');
const localforage = require('localforage');

const prefix = process.env.REACT_APP_STORAGE_PREFIX;

if (!prefix) {
	debug.warning('no localstorage prefix set..');
}

const store = {
	set: (key, value) => {
		const prefixedKey = prefix + key;
		return localforage.setItem(prefixedKey, value);
	},
	get: key => {
		const prefixedKey = prefix + key;
		return localforage.getItem(prefixedKey);
	},
	delete: key => {
		const prefixedKey = prefix + key;
		return localforage.removeItem(prefixedKey);
	},
};

export default store;
