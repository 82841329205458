import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/link-context';
import { RetryLink } from '@apollo/link-retry';
import { WebSocketLink } from '@apollo/link-ws';
import store from '../utils/localStorage';
import debug from '../utils/debug';

const wsLink = new WebSocketLink({
	uri: process.env.REACT_APP_GRAPHQL_SUBSCRIPTIONS_API,
	options: {
		reconnect: true,
		reconnectionAttempts: 10,
		lazy: true,
		connectionParams: async () => {
			const token = await store.get('token');
			return {
				authToken: token,
			};
		},
	},
});

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_GRAPHQL_API,
});

const authLink = setContext(async (_, { headers }) => {
	const token = await store.get('token');

	return {
		headers: {
			...headers,
			authorization: `Bearer ${token ? token : ''}`,
		},
	};
});

const retryLink = new RetryLink({
	delay: {
		initial: 300,
	},
	attempts: {
		max: 3,
		retryIf: (error, _operation) => {
			const errors = error && error.result?.errors;
			if (Array.isArray(errors) && errors.length > 0) {
				errors.forEach(async error => {
					if (error.extensions.code === 'UNAUTHENTICATED') {
						debug.error('authentication failure, deleting token..');
						await store.delete('token');
						_operation.setContext(op => {
							delete op.headers.authorization;
							return {};
						});
					}
				});
			}
			return !!error;
		},
	},
});

const mainLink = authLink.concat(retryLink).concat(httpLink);

const cacheRedirects = {};

const client = new ApolloClient({
	cache: new InMemoryCache({ cacheRedirects }),
	link: split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
		},
		wsLink,
		mainLink
	),
});

export default client;
