const roles = ['USER', 'ADMIN'];

export default function isUser(user) {
	const role = user?.role;

	if (!role || typeof role !== 'string') {
		return false;
	}

	return roles.includes(role);
}
